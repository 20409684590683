export default {
    namespaced: true,
    state() {
        return {
            singleProjectId: null,
            
        };
    },

    mutations: {
        setProjectId(state, value) {
            state.singleProjectId = value;
        }
    },
    actions: {
        setProjectId({ commit }, value) {
            commit('setProjectId', value);
        }
    },
    getters: {
        getProjectId(state) {
            return state.singleProjectId;
        }
    }
};