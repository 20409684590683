import {createStore} from 'vuex';

import phasesModule from './modules/phases.js';
import projectModule from './modules/project.js';


const store = createStore({
    modules: {
        phases: phasesModule,
        singleProject: projectModule,

    }
  })

  export default store;