<template>
    <div v-if="priority === 'high'" class="priority-box">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 7.33337L8 3.33337L12 7.33337M4 12.6667L8 8.66671L12 12.6667" stroke="#B45309" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        High
    </div>
    <div v-if="priority === 'normal'" class="priority-box">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 10L8 6L12 10" stroke="#FBBF24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Normal
    </div>
    <div v-if="priority === 'low'" class="priority-box">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6L8 10L12 6" stroke="#0BA5EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Low
    </div>
</template>
<script>
export default {
    name: "PriorityBox",
    props: {
        priority: {
            type: String,
            default: "low",
        }
    },
}
</script>
<style lang="scss" scoped>
    .priority-box {
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #344051;
        display: inline-flex;
        align-items: center;
        gap: 10px;
    }
</style>