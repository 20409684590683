<template>
    <div class="main-wrapper">
        <main>
            <slot></slot>
        </main>
    </div>
</template>
<script>
    export default {
    }
</script>
<style lang="scss" scoped>
    .main-wrapper {
        background-image: url(@/assets/img/login-bgr.webp);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        height: 100dvh;
    }

    main {
        height: 100%;
    }
</style>