import axios from "axios";

export const teamsApi = (query = null) => {
    return axios.get(`/api/admin/teams${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addTeamsApi = (addTeams) => {
    return axios.post(`/api/admin/teams`, {name: addTeams})
}

export const updateTeamsApi = (id, name, status) => {
    const payload = {
        id,
        name,
        status: status
    };
    return axios.post(`/api/admin/teams/`+ id, payload)
}

export const deleteTeamsApi = (id) => {
    return axios.delete(`/api/admin/teams/` + id )
}

