import moment from "moment";

export const dateStringToUnixStamp = (dateString) => {
  return new moment(dateString).unix() * 1000;
};

export const unixStampToDateString = (unixStamp) => {
  return new moment(unixStamp * 1000).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]');
}

export const dateFromString = (dateString) => {
  return new moment(dateString);
};

export const dateNow = () => {
  return new moment();
};

export const dateYesterday = () => {
  return new moment().subtract(1, 'day');
};