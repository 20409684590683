<template>
    <div v-if="status === 'not-started'" class="status-box">
        Not Started
    </div>
    <div v-if="status === 'on-hold'" class="status-box">
        On Hold
    </div>
    <div v-if="status === 'maintenance'" class="status-box">
        Maintenance
    </div>
    <div v-if="status === 'completed'" class="status-box">
        Completed
    </div>
    <div v-if="status === 'in-progress'" class="status-box">
        In Progress
    </div>
    <div v-if="status === 'active'" class="status-box">
        Active
    </div>
    <div v-if="status === 'inactive'" class="status-box">
        Inactive
    </div>
    <div v-if="status === 'done'" class="status-box">
        Done
    </div>
</template>
<script>
export default {
    name: "StatusBox",
    props: {
        status: {
            type: String,
            default: "not-started",
        }
    },
}
</script>
<style lang="scss" scoped>
    .status-box {
        padding: 0 10px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #344051;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        height: 24px;
        border: 1px solid #E4E7EC;
    }
</style>