export const priorities = [
    {
        id:1,
        name: "normal",
    },
    {
        id:2,
        name: "high",
    },
    {
        id:3,
        name: "low",
    },
]