export default {
    listIcon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 38"><g filter="url(#A)"><path d="M3 11a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V11z" fill="url(#B)"/><path d="M16 14.5h9m-12 .008l.008-.008M13 19.008l.008-.008M13 23.508l.008-.008M16 19h9m-9 4.5h9" stroke="#141c25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><filter id="A" x="0" y="0" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="A"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="3" operator="dilate" in="SourceAlpha" result="C"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.956863 0 0 0 0 0.968627 0 0 0 1 0"/><feBlend in2="A" result="C"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="2" operator="dilate" in="SourceAlpha"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend in2="C"/><feBlend in="SourceGraphic"/></filter><linearGradient id="B" x1="19" y1="3" x2="19" y2="35" gradientUnits="userSpaceOnUse"><stop stop-color="#f9fafb"/><stop offset="1" stop-color="#f2f4f7"/></linearGradient></defs></svg>',
    teamsIcon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" fill="none"><g filter="url(#A)"><path d="M3 11a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V11z" fill="url(#B)"/><path d="M10.75 25v-.75A5.25 5.25 0 0 1 16 19m5.25 6v-.75A5.25 5.25 0 0 0 16 19m0 0a3 3 0 1 0 0-6 3 3 0 1 0 0 6zm11.25 1.875V20.5a3.75 3.75 0 0 0-3.75-3.75m0 0a3.75 3.75 0 0 0-3.75 3.75m3.75-3.75a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 1 0 0 4.5z" stroke="#141c25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><filter id="A" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="A"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="3" operator="dilate" in="SourceAlpha" result="C"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.956863 0 0 0 0 0.968627 0 0 0 1 0"/><feBlend in2="A" result="C"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="2" operator="dilate" in="SourceAlpha"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend in2="C"/><feBlend in="SourceGraphic"/></filter><linearGradient id="B" x1="19" y1="3" x2="19" y2="35" gradientUnits="userSpaceOnUse"><stop stop-color="#f9fafb"/><stop offset="1" stop-color="#f2f4f7"/></linearGradient></defs></svg>',
    skillsIcon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 38"><g filter="url(#A)"><path d="M3 11a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V11z" fill="url(#B)"/><path d="M14.875 15.25v2.625a1.5 1.5 0 0 0 1.5 1.5h5.25a1.5 1.5 0 0 0 1.5-1.5V15.25M19 19.375v3.375m-6.3-7.5h4.35a.45.45 0 0 0 .45-.45v-2.85a.45.45 0 0 0-.45-.45H12.7a.45.45 0 0 0-.45.45v2.85a.45.45 0 0 0 .45.45zm4.125 11.25h4.35a.45.45 0 0 0 .45-.45V23.2a.45.45 0 0 0-.45-.45h-4.35a.45.45 0 0 0-.45.45v2.85a.45.45 0 0 0 .45.45zm4.125-11.25h4.35a.45.45 0 0 0 .45-.45v-2.85a.45.45 0 0 0-.45-.45h-4.35a.45.45 0 0 0-.45.45v2.85a.45.45 0 0 0 .45.45z" stroke="#141c25" stroke-width="1.5"/></g><defs><filter id="A" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="A"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="3" operator="dilate" in="SourceAlpha" result="C"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.956863 0 0 0 0 0.968627 0 0 0 1 0"/><feBlend in2="A" result="C"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="2" operator="dilate" in="SourceAlpha"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend in2="C"/><feBlend in="SourceGraphic"/></filter><linearGradient id="B" x1="19" y1="3" x2="19" y2="35" gradientUnits="userSpaceOnUse"><stop stop-color="#f9fafb"/><stop offset="1" stop-color="#f2f4f7"/></linearGradient></defs></svg>',
    seniorityIcon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 38"><g filter="url(#A)"><path d="M3 11a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V11z" fill="url(#B)"/><path d="M20.704 17.834A4.5 4.5 0 0 1 19 26.5a4.5 4.5 0 0 1-2.013-8.526m3.717-.14a4.47 4.47 0 0 0-1.133-.298m1.133.298L23.5 11.5m-3.929 6.036c-.187-.024-.378-.036-.571-.036a4.48 4.48 0 0 0-2.013.474m2.584-.438L16.626 11.5m.361 6.474L13.75 11.5m7.02 0l-.78 1.875" stroke="#141c25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><filter id="A" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="A"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="3" operator="dilate" in="SourceAlpha" result="C"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.956863 0 0 0 0 0.968627 0 0 0 1 0"/><feBlend in2="A" result="C"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="2" operator="dilate" in="SourceAlpha"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend in2="C"/><feBlend in="SourceGraphic"/></filter><linearGradient id="B" x1="19" y1="3" x2="19" y2="35" gradientUnits="userSpaceOnUse"><stop stop-color="#f9fafb"/><stop offset="1" stop-color="#f2f4f7"/></linearGradient></defs></svg>',
    positionsIcon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 38 38"><g filter="url(#A)"><path d="M3 11a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H11a8 8 0 0 1-8-8V11z" fill="url(#B)"/><path d="M12.25 25H19m0 0h6.75M19 25v-2.25m.346-1.915l3.164-3.797a.45.45 0 0 0 0-.576l-3.164-3.797a.45.45 0 0 0-.691 0l-3.164 3.797a.45.45 0 0 0 0 .576l3.164 3.797a.45.45 0 0 0 .691 0z" stroke="#141c25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><filter id="A" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="A"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="3" operator="dilate" in="SourceAlpha" result="C"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 0.94902 0 0 0 0 0.956863 0 0 0 0 0.968627 0 0 0 1 0"/><feBlend in2="A" result="C"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="B"/><feMorphology radius="2" operator="dilate" in="SourceAlpha"/><feOffset/><feComposite in2="B" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend in2="C"/><feBlend in="SourceGraphic"/></filter><linearGradient id="B" x1="19" y1="3" x2="19" y2="35" gradientUnits="userSpaceOnUse"><stop stop-color="#f9fafb"/><stop offset="1" stop-color="#f2f4f7"/></linearGradient></defs></svg>',
    edit: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" ><path d="M11.969 4.71l1.233-1.233c.651-.651 1.706-.651 2.357 0l1.178 1.179c.651.651.651 1.706 0 2.357l-1.233 1.233M11.969 4.71l-8.013 8.013c-.277.277-.447.642-.482 1.032l-.202 2.284c-.046.517.387.949.903.904l2.284-.202c.39-.034.755-.205 1.032-.482l8.013-8.013M11.969 4.71l3.536 3.536" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    delete: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none"><path d="M16.667 7.5l-1.662 9.455c-.14.797-.832 1.378-1.642 1.378H6.637c-.809 0-1.501-.581-1.641-1.378L3.333 7.5M17.5 5h-4.687m0 0V3.333c0-.92-.746-1.667-1.667-1.667H8.854c-.92 0-1.667.746-1.667 1.667V5m5.625 0H7.188M2.5 5h4.688" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    modalPen: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none"><path d="M.5 16C.5 7.44 7.44.5 16 .5h16C40.56.5 47.5 7.44 47.5 16v16c0 8.56-6.94 15.5-15.5 15.5H16C7.44 47.5.5 40.56.5 32V16z" stroke="#e4e7ec"/><path d="M13.5 34.5h21M24.259 16.8l3.3-3.3 5.775 5.775-3.3 3.3M24.259 16.8l-6.541 6.541c-.219.219-.342.515-.342.825v5.291h5.291c.309 0 .606-.123.825-.342l6.541-6.541M24.259 16.8l5.775 5.775" stroke="#1a232d" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    
}