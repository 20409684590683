import axios from "axios";

export const positionsApi = (query = null) => {
    return axios.get(`/api/admin/positions${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addPositionsApi = (addPositions) => {
    return axios.post(`/api/admin/positions`, {name: addPositions})
}

export const updatePositionsApi = (id, name, status) => {
    const payload = {
        id,
        name,
        status: status
    };
    return axios.post(`/api/admin/positions/`+ id, payload)
}

export const deletePositionsApi = (id) => {
    return axios.delete(`/api/admin/positions/` + id )
}

