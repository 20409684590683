<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" :class="size">
        <div class="modal-header">
          <slot name="header"></slot>
          <a v-if="closeButton" class="close" @click="$emit('close')">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.1712 14.8284L11.9996 12M11.9996 12L14.8281 9.17157M11.9996 12L9.1712 9.17157M11.9996 12L14.8281 14.8284M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                stroke="#637083"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    size: {
      type: String,
      default: '',
    },
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    document.getElementsByTagName('body')[0].classList.add('modal-active');
  },
  beforeUnmount() {
    document.getElementsByTagName('body')[0].classList.remove('modal-active');
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(#141c25, 0.34);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  width: 100%;
}

.modal-container {
  max-width: 450px;
  width: calc(100% - 20px);
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0px 20px 25px -5px rgba(20, 28, 37, 0.1);
  padding: 0 24px;
  border-radius: 12px;
  max-height: calc(100vh - 60px);

  @media (max-width: 991px) {
    padding: 20px;
    box-shadow: none;
  }
}

.close {
  cursor: pointer;

  svg {
    path {
      transition: all 0.3s ease;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #03070a;
      }
    }
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-gray);
  padding: 24px 0;
}

.modal-body {
  padding: 24px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--light-gray);
  padding: 24px 0;
}
</style>
