import axios from "axios";

export const locationApi = (query = null) => {
    return axios.get(`/api/admin/locations${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addLocationApi = (addLocation) => {
    return axios.post(`/api/admin/locations`, {name: addLocation})
}

export const updateLocationApi = (id, name, status) => {
    const payload = {
        id,
        name,
        status: status
    };
    return axios.post(`/api/admin/locations/`+ id, payload)
}

export const deleteLocationApi = (id) => {
    return axios.delete(`/api/admin/locations/` + id )
}

