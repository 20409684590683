<template>
  <header class="header-main">
    <Breadcrumb />
    <slot></slot>
  </header>
</template>
<script>
import Breadcrumb from '../Breadcrumb/Breadcrumb.vue';
export default {
  name: 'Header',
  components: {
    Breadcrumb,
  },
};
</script>
<style lang="scss" scoped>
.header-main {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-box {
  max-width: 320px;
}
</style>
