<template>
  <div>
    <GanttElastic ref="gantt" :tasks="timelineTasks" :options="timelineOptions"></GanttElastic>
    <button @click="buttonClick">Test</button>
  </div>
</template>

<script>
  import { GanttElastic } from "gantt-elastic-wrapvue3"
  import { dateYesterday, dateNow } from "@/service/DateTime"
  import { createPlaceHolderTaskFromApiData } from "@/service/Timeline"
  import "gantt-elastic-wrapvue3/dist/style.css"

  export default {
    name: 'Timeline',
    components: {
      GanttElastic,
    },
    props: {
      tasks: Array,
      dateFrom: Date,
      dateTo: Date,
    },
    data() {
      return {
        timelineTasks: [],
        timelineOptions: {
          maxRows: 100,
          maxHeight: 500,
          times: {
            timeZoom: 20,
          },
          scope: {
            before: 0,
            after: -1,
          },
          chart: {
          },
          row: {
            height: 24,
          },
          calendar: {
            hour: {
              display: false,
            },
            workingDays: [1, 2, 3, 4, 5],
          },
          taskList: {
            // display: false,
            columns: [
              {
                id: 1,
                label: '',
                value: 'description',
                width: 200,
                expander: true,
              },
            ]
          }
        }
      }
    },
    methods: {
      buttonClick() {
        // this.$refs.gantt.options.scale_unit = "month";
        // console.log(this.$refs.gantt.options);
        // this.$refs.gantt.options.row.height = this.$refs.gantt.options.row.height + 1;

        // this.$refs.gantt.options.times.timeZoom = this.$refs.gantt.options.times.timeZoom + 1;
        // this.tasks.push({
        //   id: 10,
        //     description: 'Lafarge',
        //     start: dateFromString('2024-10-01T00:00:00.000000Z'),
        //     end: dateFromString('2025-01-31T23:59:59.000000Z'),
        //     progress: 0,
        //     type: 'task',
        //     parentId: 4,
        // });
      }
    },
    watch: {
      tasks: {
        immediate: true,
        handler(newVal, oldVal) {
          this.timelineTasks = newVal;
        },
      },
    },
    created() {
      this.timelineTasks.push(createPlaceHolderTaskFromApiData(dateYesterday(), dateNow()));
    }
  }
</script>

<style>

</style>