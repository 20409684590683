export default {
    namespaced: true,
    state() {
        return {
            editPhaseId: null,
        };
    },

    mutations: {
        setPhaseId(state, value) {
            state.editPhaseId = value;
        }
    },
    actions: {
        setPhaseId({ commit }, value) {
            commit('setPhaseId', value);
        }
    },
    getters: {
        getPhaseId(state) {
            return state.editPhaseId;
        }
    }
};