<template>
  <div class="main-wrapper">
    <Aside/>
    <slot></slot>
  </div>
</template> 
<script>
import Aside from '../components/Aside/Aside.vue';

export default {
  components: {
    Aside,
  },
};
</script>
<style lang="scss" scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: var(--aside-width);
}
</style>
