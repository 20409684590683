import axios from "axios";
import { createFormData } from "@/service/general";

export const getProjectsApi = (query = null) => {
    return axios.get(`/api/admin/projects${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const getProjectDetailsApi = (id) => {
    return axios.get(`/api/admin/projects/${id}`)
}

export const addProjectsApi = (payload) => {
    const formData = createFormData(payload)
    return axios.post(`/api/admin/projects`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const updateProjectApi = (id, payload) => {
    const formData = createFormData(payload)
    return axios.post(`/api/admin/projects/${id}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export const deleteProjectApi = (id) => {
    return axios.delete(`/api/admin/projects/` + id )
}

export const addPhaseApi = (id, payload) => {
    return axios.put(`/api/admin/projects/${id}/add-phase`, payload);
}

export const getPhaseDetailsApi = (projectId, id) => {
    return axios.get(`/api/admin/projects/${projectId}/view-phase/${id}`);
}

export const updatePhaseApi = (projectId, id, payload) => {
    return axios.put(`/api/admin/projects/${projectId}/update-phase/${id}`, payload);
}

export const deletePhaseApi = (projectId, id,) => {
    return axios.patch(`/api/admin/projects/${projectId}/delete-phase/${id}`);
}
