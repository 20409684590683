<template>
    <div>
        <Timeline
            :groups="timelineGroups"
            :items="timelineItems"
            :viewportMin="timelineViewportMin"
            :viewportMax="timelineViewportMax"
            :minViewportDuration="1000 * 60 * 60 * 24"
            :maxViewportDuration="1000 * 60 * 60 * 24 * 30 * 24"
            :maxOffsetOutsideViewport="30"
            :changeScale="timelineChangeScale"
         />
    </div>
</template>

<script>
  import { Timeline } from 'vue-timeline-chart';
  import 'vue-timeline-chart/style.css';
  import { getTestDataApi } from '@/api/bookings';
  import { dateStringToUnixStamp, unixStampToDateString } from '@/service/DateTime';
  import { toast } from 'vue3-toastify';

  export default {
    name: 'Test',
    components: {
        Timeline,
    },
    data() {
        return {
            timelineGroups: [],
            timelineItems: [],
            timelineViewportMin: 0, //1703112200000,
            timelineViewportMax: 1, //1714566600000,

        };
    },
    methods: {
        timelineChangeScale(e) {
            console.log('timelineChangeScale', e);
        },
        addTimelineItem(itemData) {
            // console.log(itemData);

            const timelineGroup = {
                id: itemData.id,
                label: 'Group ' + itemData.id,
            };

            this.timelineGroups.push(timelineGroup);

            const end = itemData.end_date ? dateStringToUnixStamp(itemData.end_date) : (this.timelineViewportMax + 1000000);

            const timelineItem = {
                group: timelineGroup.id,
                type: 'range',
                // title: 'Resource ' + itemData.id,
                start: dateStringToUnixStamp(itemData.start_date),
                end,
                id: 'Booking ' + itemData.id,
            };

            // if (itemData.end_date) {
            //     timelineItem.end = dateStringToUnixStamp(itemData.end_date);
            // }

            // console.log(timelineItem);
            this.timelineItems.push(timelineItem);
        },
    },
    async created() {
        try {
            const { data } = await getTestDataApi();
            this.timelineViewportMin = dateStringToUnixStamp(data.start_date);
            this.timelineViewportMax = dateStringToUnixStamp(data.end_date);

            // console.log('timelineViewportMin', unixStampToDateString(this.timelineViewportMin), this.timelineViewportMin);
            // console.log('timelineViewportMax', unixStampToDateString(this.timelineViewportMax), this.timelineViewportMax);

            if (Array.isArray(data.data)) {
                data.data.forEach(itemData => {
                    this.addTimelineItem(itemData);
                });
            }
        } catch (e) {
            toast.error(e.response?.data.message);
        }

        // this.timelineGroups = [
        //     { id: 'group1', label: 'Group 1' },
        //     { id: 'group2', label: 'Group 2' },
        // ];

        // this.timelineItems = [
        //     { group: 'group1', type: 'point', start: 1705878000000, cssVariables: { '--item-background': 'var(--color-2)' } },
        //     { group: 'group1', type: 'range', start: 1707135072000, end: 1708431072000, cssVariables: { '--item-background': 'var(--color-4)' } },
        //     { group: 'group2', type: 'range', start: 1706790600000, end: 1706877000000 },
        // ];

        // const t = '2024-10-25T07:45:26.000000Z';
        // console.log(t);

        // const t1 = this.dateStringToUnixStamp(t);
        // //2024-10-25T07:45:26.000000Z
        // //2024-10-25T09:45:26.0000Z

        // console.log(t1);

        // const t2 = this.unixStampToDateString(t1);
        // console.log(t2);
    },
  }
</script>
<style lang="scss" scoped>

</style>