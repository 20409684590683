<template>
    <div class="upload-image-wrapper">
        <input ref="photo" @change="setPhoto" type="file" id="upload-photo">

        <label class="upload-image" for="upload-photo" :style="'background-image: url(' + photoPreview + ')'">
            <AuthorizedImg class="loadedImg" v-if="!photoPreview && data.file?.source?.original" :src="data.file?.source?.original" />
            <svg v-if="!photoPreview" width="30" viewBox="0 0 448 512">
                <path fill="#414E62" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/>
            </svg>
        </label>
        <p class="mb0">{{data.first_name}} {{data.last_name}}</p>
    </div>

    
</template>
<script>
import AuthorizedImg from '../../components/AuthorizedImg/AuthorizedImg.vue';
export default {
    name: "UploadImage",
    props: {
        data: {
            type: Object
        }
    },
    components: {
        AuthorizedImg,
    },
    emits:['uploadImg'],
    data() {
        return {
            photo: null,
            photoPreview: null,
        }
    },
    methods: {
        async setPhoto($event) {
            const file = $event.target.files[0];
            if (!$event.target.files[0]) {
                return;
            }
            this.photo = file;
            const reader = new FileReader();
            reader.onload = (e) => {
                this.photoPreview = e.target.result;  // Store the base64 string for preview
            };
            reader.readAsDataURL(file);
            this.$emit('uploadImg', this.photo)
        },
    }, 
}
</script>
<style lang="scss" scoped>
    .upload-image-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-size: 18px;
        }

        input {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
    }

    .upload-image {
        width: 80px;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        background-size: cover;
        background-position: 50% 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #e4e7ec;
        overflow: hidden;

        .loadedImg {
            object-fit: cover;
            max-width: initial;
            min-width: 100%;
            min-height: 100%;
        }
    }
</style>