<template>
    <div class="loader-wrapper">
        <div class="loader"></div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .loader-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loader {
        position: relative;
        width: 100px;
        height: 100px;
    }

    .loader:before , .loader:after{
        content: '';
        border-radius: 50%;
        position: absolute;
        inset: 0;
        box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.3) inset;
    }
    .loader:after {
        box-shadow: 0 3px 0 var(--dark) inset;
        animation: rotate .5s linear infinite;
    }

    @keyframes rotate {
        0% {  transform: rotate(0)}
        100% { transform: rotate(360deg)}
    }
</style>