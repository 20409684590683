<template>
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModal"
          class="modal modal--skills-phase"
          :closeButton="false"
          @close="openModal = true"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <img
                  src="@/assets/img/required-skills.svg"
                  alt="required-skills"
                />
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Required Skills</h2>
                <p class="mb0">List of project required skills</p>
              </div>
            </div>
          </template>
          <template #body>

            <table class='table mb0'>
                <thead>
                    <tr>
                        <th>Required Skill</th>
                        <th>Required hours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="skill in skills" :key="skill.id">
                        <td>{{ skill.name }}</td>
                        <td>
                            <template v-if="skill?.pivot?.ongoing">
                                {{ skill?.pivot?.ongoing === 1 ? 'Ongoing' : ''}}
                            </template>
                            <template v-else>
                                {{ skill?.pivot?.hours }} hours
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModal = false"
                >Cancel</a
              >
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <div class="card" >
        <div class="card__header o-flex o-flex--center o-flex--justify">
            <div class="flag">
                <div class="flag__icon">
                    <img src="@/assets/img/project-preview.svg" alt="project-preview" />
                </div>
                <div class="flag__content">
                    <h2 class="u-font-500 mb0">Phases</h2>
                    <p class="mb0">Manage project phases here</p>
                </div>
            </div>
            <router-link :to="{ name: 'add-phase', params: { id: id } }" class="button button--primary">Add new</router-link>
        </div>
        <div class="card__body" v-if="data.length">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Required Skills</th>
                            <th>Status</th>
                            <th>Booking</th>
                            <th width="10%" class="u-text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in data" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{item.start_date !== null ? formatDate(item.start_date) : ''}}</td>
                            <td>{{item.end_date !== null ? formatDate(item.end_date) : ''}}</td>
                            <td>
                                <a href="javascript:;" @click="viewSkills(item.skills)" class="view-skills" v-if="item.skills.length">
                                    <svg fill="none" width="14" viewBox="0 0 14 10">
                                        <path stroke="#B3D7FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                        <path stroke="#B3D7FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                    </svg>
                                    View Skills
                                </a>
                            </td>
                            <td><StatusBox :status="item.status"/></td>
                            <td>
                                <a href="javascript:;" class="book-resources">
                                    <svg fill="none" width="14" viewBox="0 0 14 15">
                                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1 6.667v6C1 13.403 1.597 14 2.333 14h9.334c.736 0 1.333-.597 1.333-1.333v-6m-12 0h12m-12 0V4c0-.736.597-1.333 1.333-1.333h1.334m9.333 4V4c0-.736-.597-1.333-1.333-1.333h-.334M9 2.667V1.333m0 1.334V4m0-1.333H6M3.667 4V1.333"/>
                                    </svg>
                                    Book Resources
                                </a>
                            </td>
                            <td class="u-text-center">
                                <router-link :to="{ name: 'view-edit-phase', params: { id: item.id } }" @click.prevent="updateEditPhaseId(item.id)"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.9693 4.70964L13.2026 3.47631C13.8535 2.82544 14.9088 2.82544 15.5597 3.47631L16.7382 4.65483C17.3891 5.3057 17.3891 6.36097 16.7382 7.01185L15.5049 8.24517M11.9693 4.70964L3.95627 12.7227C3.67954 12.9994 3.50902 13.3647 3.47458 13.7545L3.27284 16.0382C3.2272 16.5549 3.6596 16.9873 4.17627 16.9417L6.45996 16.7399C6.84979 16.7055 7.21508 16.535 7.49181 16.2582L15.5049 8.24517M11.9693 4.70964L15.5049 8.24517" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg></router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Modal from '@/components/Modal/Modal.vue';
import StatusBox from '../../components/StatusBox/StatusBox.vue';
import moment from "moment";

export default {
    name: "Phases",
    props: {
        data: {
            type: Array,
            default: []
        },
        id: {
            type: Number,
            default: null,
        }
    },
    data() {
        return {
            openModal: false,
            skills: null,
        }
    },
    components: {
        StatusBox,
        Modal,
    },
    methods: {
        updateEditPhaseId(id) {
            localStorage.setItem('phaseId', id);
        },
        formatDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },

        viewSkills(skills) {
            this.openModal =  true;
            this.skills = skills
        }
    }
}
</script>
<style lang="scss" scoped>

    .card {
        margin-top: 16px;
        flex: initial;
    }

    .view-skills {
        padding: 0 8px;
        border-radius: 6px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        background-color: #0166FF;
    }

</style>

<style lang="scss">
    .modal--skills-phase {
        .modal-footer {
            border-top: 0;
            padding-top: 0;
        }
    }

    .book-resources {
        padding: 0 12px;
        height: 32px;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        gap:12px;
        font-size: 14px;
        font-weight: 500;
        background-color: #141C25;
        color: #fff;

        &:hover {
            background-color: darken(#141C25, 10%);
            color: #fff;
        }
    }
</style>