<template>
    <div class="card h-100" v-if="loadPage">
        <div class="card_body h-100">
            <Loader/>
        </div>
    </div>
    <Form v-else :validation-schema="schema">
        <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/add-project.svg" alt="add-project" />
                    </div> 
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">{{projectName}}</h2>
                        <p class="mb0">Manage your project here</p>
                    </div>
                </div>
                <a href="javascript:;" v-if="accordion" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                    <svg fill="none" width="16" viewBox="0 0 16 16">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                    </svg>
                </a>
            </div>
            <transition name="fade">
                <div class="card__body" v-if="accordionActive">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Project name <span>*</span></p>
                                <div class="relative">
                                    <Field type="text" name="projectName" class="input input--primary" :placeholder="'Enter'" v-model="projectName"/>
                                    <ErrorMessage class="text-error" name="projectName"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Client name <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="clientSelected" placeholder="Select one" :options="allClients" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Start Date <span>*</span></p>
                                <div class="relative">
                                <Field name="startDate" v-slot="{ field }">
                                    <vue-date-picker
                                        auto-apply
                                        :enable-time-picker="false"
                                        v-bind="field"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="startDate"
                                        :ui="{ input: 'date-picker' }"
                                    />
                                </Field>
                                <ErrorMessage class="text-error" name="startDate"  />
                            </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>End Date</p>
                                <div class="relative">
                                    <vue-date-picker 
                                    auto-apply
                                    :enable-time-picker="false"
                                    :model-type="'yyyy-MM-dd'"
                                    :format="'dd.MM.yyyy'"
                                    placeholder="Select Date"
                                    v-model="endDate"
                                    name="endDate"
                                    :ui="{ input: 'date-picker' }"
                                    :disabled="!startDate"
                                    :min-date="minEndDate"
                                    />
                                    <ErrorMessage class="text-error" name="endDate"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Project Manager <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="projectManagerSelected" placeholder="Select one" :options="formattedProjectManagers" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Priority <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="prioritiesSelected" placeholder="Select one" :options="allPriorities" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Status <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="statusSelected" placeholder="Select one" :options="statuses" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Project Color</p>
                                <color-picker format="'hex'" class="input input--primary" v-model:pureColor="pureColor"/>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-12">
                            <div class="input-box">
                                <p>Description</p>
                                <div class="relative">
                                    <Field as="textarea" type="text" name="description" class="input input--primary input--textarea" :placeholder="'Message...'" v-model="description" maxlength="250"/>
                                    <span class="max-characters">{{ description?.length }}/250</span>
                                    <ErrorMessage class="text-error" name="description"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade">
                <div class="card__footer card--border o-flex o-flex--justify o-flex--row-reverse" v-if="accordionActive">
                    <div class="o-flex o-flex--center gap-10">
                        <router-link :to="{name: 'project'}" class="button button--secondary">Cancel</router-link>
                        <button type="button" @click="submit" class="button button--primary" :disabled="working || disabledButton">Save Changes</button>
                    </div>
                    <button type="button" v-if="payloadCheck" @click="$emit('delete')" class="button button--delete">Delete</button>
                </div>
            </transition>
        </div>
    </Form>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueMultiselect from 'vue-multiselect';
import Loader from '../../components/Loader/Loader.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { getProjectManagers, getClients } from '../../service/general';
import { priorities } from '@/utilities/priorities';
import { status } from '@/utilities/status';
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import moment from "moment";

export default {
    name: "ProjectForm",
    components: {
        Field,
        Form,
        ErrorMessage,
        VueDatePicker,
        VueMultiselect,
        ColorPicker ,
        Loader,
    },
    emits: ['loading', 'formData', 'delete'],
    props: {
        working: {
            type: Boolean,
            default: false,
        },
        payload: {
            type: Object,
            default: {},
        },
        accordion: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loadPage: true,
            accordionActive: true,
            projectName: '',
            startDate: null,
            endDate: null,
            description: '',
            allClients: [],
            allProjectManagers: [],
            allStatuses: [],
            allPriorities: [],
            clientSelected: null,
            projectManagerSelected: null,
            prioritiesSelected: null,
            statusSelected: null,
            schema: yup.object({
                projectName: yup
                .string()
                .required('Project name is required')
                .min(2, 'Must be at least 2 characters'),
                clientName: yup
                .string()
                .required('Client name is required')
                .min(2, 'Must be at least 2 characters'),
                startDate: yup
                .date()
                .nullable()
                .required('Start date is required'),
            }),
            pureColor: "#000000",
        }
    },

    computed: {
        disabledButton() {
            return this.projectName === '' || 
            this.clientSelected === null || 
            this.startDate === null || 
            this.projectManagerSelected === null || 
            this.prioritiesSelected === null || 
            this.statusSelected === null;
        },
        statuses() {
            return this.allStatuses.filter(item => 
                item.name === "not-started" || 
                item.name === "in-progress" || 
                item.name === "on-hold" || 
                item.name === "maintenance" || 
                item.name === "completed"
            );
        },

        formattedProjectManagers() {
            return this.allProjectManagers.map(manager => ({
                ...manager,
                name: `${manager.first_name} ${manager.last_name}`,
            }));
        },

        payloadCheck() {
            return this.payload && Object.keys(this.payload).length > 0;
        },

        minEndDate() {
            if (!this.startDate) return null;

            const startDate = new Date(this.startDate);
            startDate.setDate(startDate.getDate() + 1);

            return startDate.toISOString().split('T')[0]; // format to 'yyyy-MM-dd'
        },
    },

    methods: {
        
        updateData(payload) {
            if (payload && Object.keys(payload).length > 0) {
                if (payload.name !== undefined) this.projectName = payload.name;
                if (payload.client !== undefined) this.clientSelected = payload.client;
                if (payload.start_date !== null) this.startDate = moment(payload.start_date ).format('YYYY-MM-DD');
                if (payload.end_date !== null) this.endDate = moment(payload.end_date ).format('YYYY-MM-DD');
                if (payload.project_manager !== undefined) {
                    const { first_name, last_name } = payload.project_manager;

                    this.projectManagerSelected = {
                        ...payload.project_manager,
                        name: `${first_name} ${last_name}`
                    };
                };
                if (payload.priority !== undefined) {
                    const selectedPriority = priorities.find(p => p.name === payload.priority);
                    if (selectedPriority) {
                        this.prioritiesSelected = { id: selectedPriority.id, name: selectedPriority.name };
                    }
                };
                if (payload.status !== undefined) {
                    const selectedStatus = status.find(s => s.name === payload.status);
                    if (selectedStatus) {
                        this.statusSelected = { id: selectedStatus.id, name: selectedStatus.name };
                    }
                }
                if (payload.color !== undefined) this.pureColor = payload.color;
                if (payload.description !== undefined) this.description = payload.description;

            }


            this.loadPage = false;
            this.$emit('loading', false);
        },
        async submit() {

            const payload = {
                name: this.projectName,
                client_id: this.clientSelected.id,
                start_date: this.startDate,
                end_date: this.endDate,
                project_manager_id: this.projectManagerSelected.id,
                priority: this.prioritiesSelected.name,
                status: this.statusSelected.name,
                color: this.pureColor,
                description: this.description,
                
            }
            this.$emit('formData', payload);
        }
    },
    async created() {
        const [allProjectManagers, allClients]  = await Promise.all([getProjectManagers(), getClients()])
        this.allProjectManagers = allProjectManagers;
        this.allStatuses = status;
        this.allPriorities = priorities;
        this.allClients = allClients;
        this.updateData(this.payload);
    },
}
</script>
<style lang="scss" scoped>
    .card__header {
        padding: 9px 0;

        &.active {
            padding-bottom: 9px;
            border-bottom: none;
        }
    }

    .card__body {
        padding: 24px 0;
        margin-top: 10px;
        border-top: 1px solid #E4E7EC;
    }

    .card__footer {
        padding-top: 24px;
        padding-bottom: 9px;
        gap: 10px;
    }

    .accordion-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(21, 28, 36, 0.05);
        background-color: #141C25;
        svg {
            transform: rotate(180deg);
            transition: all .3s ease;
        }

        &:hover {
            background-color: darken(#141C25, 10%);
        }
        
        &.active {
            svg {
                transform: rotate(0deg);
            }
        }
    }

</style>