<template>
    <a href="javascript:;" @click="logout" class="logout aside__link">
        <span class="o-flex o-flex--center link-wrapper">
            <img
            src="@/assets/img/log-out.svg"
            class="mr--"
            alt="page"
            />Logout
        </span>
    </a>
</template>

<script>

import { logout as logoutService } from "../../service/Auth";
export default {
    name: "Logout",
    methods: {
        async logout() {
            await logoutService()
            await this.$router.push({name:'login'})
        }
    }
}
</script>

<style scoped lang="scss">
    .logout {
        color: var(--aside-link-color);
        font-size: 14px;
        line-height: 1.3;
        display: flex;
        border-radius: 8px;
        &:hover,
        &.active {
            background-color: var(--aside-link-hover-color);
        }
    }

    .link-wrapper {
        padding: 10px 8px;
    }
</style>