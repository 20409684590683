<template>
  <!-- <header>
    hello gantt
  </header> -->

  <div>
    <GanttElastic ref="gantt" :options="options" :tasks="tasks">
      <template v-slot:header>
        <!-- <GanttElasticHeader2 ></GanttElasticHeader2> -->
      </template>

    </GanttElastic>
    <button @click="buttonClick">Test</button>
  </div>
</template>

<script>
  // import { Timeline } from 'vue-timeline-chart';
  // import 'vue-timeline-chart/style.css';
  // import { getTestDataApi } from '@/api/bookings';
  // import { dateStringToUnixStamp, unixStampToDateString } from '@/service/DateTime';
  import { toast } from 'vue3-toastify';
  import dayjs from "dayjs";
  import {GanttElastic, GanttElasticHeader2} from "gantt-elastic-wrapvue3"
  import "gantt-elastic-wrapvue3/dist/style.css"
  import { dateFromString } from "@/service/DateTime"

  export default {
    name: 'Test2',
    components: {
      GanttElastic,
      GanttElasticHeader2,
    },
    data() {
    return {
      tasks: [
        // {
        //   // dummy
        //   start: this.getDate(-24 * 4),
        //   duration: 1,
        //   progress: 0,
        //   type: 'project',
        // },

        // {
        //   id: 1,
        //   description: 'Nikola Skaramuca',
        //   start: dateFromString('2024-10-01T23:59:59.000000Z'),
        //   duration: 1,
        //   progress: 0,
        //   type: 'task',
        // },

        {
          id: 1,
          description: 'Nikola Skaramuca',
          start: dateFromString('2024-10-01T00:00:00.000000Z'),
          end: dateFromString('2024-10-31T23:59:59.000000Z'),
          progress: 0,
          type: 'task',
          style: {
            base: {
              display: 'none',
            }

          }
        },

        {
          id: 2,
          label: 'Some label',
          description: 'Workforce Planning',
          start: dateFromString('2024-10-02T00:00:00.000000Z'),
          end: dateFromString('2024-10-31T23:59:59.000000Z'),
          progress: 0,
          type: 'task',
          parentId: 1,
        },

        {
          id: 3,
          label: 'Some label',
          description: 'Lafarge',
          start: dateFromString('2024-10-02T00:00:00.000000Z'),
          end: dateFromString('2025-01-31T23:59:59.000000Z'),
          progress: 0,
          type: 'task',
          parentId: 1,
          style: {
            "task-list-header-label": {
              textAlign: "center",
              width: "100%"
            },
            "task-list-item-value-container": {
              textAlign: "center"
            },
          }
        },

        {
          id: 4,
          description: 'Joca Fitilj',
          start: dateFromString('2024-10-01T00:00:00.000000Z'),
          duration: 1,
          progress: 0,
          type: 'task',
        },

        {
          id: 5,
          description: 'Workforce Planning',
          start: dateFromString('2024-10-01T00:00:00.000000Z'),
          end: dateFromString('2024-10-31T23:59:59.000000Z'),
          progress: 0,
          type: 'task',
          parentId: 4,
        },

        {
          id: 6,
          description: 'Lafarge',
          start: dateFromString('2024-10-01T00:00:00.000000Z'),
          end: dateFromString('2025-01-31T23:59:59.000000Z'),
          progress: 0,
          type: 'task',
          parentId: 4,
        }

      ],
      options: {
        maxRows: 100,
        maxHeight: 500,
        times: {
          // timeScale: 60 * 1000,
          timeZoom: 20,
          // firstTime: dateFromString('2022-10-01T00:00:00.000000Z'),
          // lastTime: dateFromString('2022-10-15T00:00:00.000000Z'),


          // firstTaskTime: dateFromString('2022-10-01T00:00:00.000000Z'),
          // lastTaskTime: dateFromString('2022-10-15T00:00:00.000000Z'),


          // start: dateFromString('2024-10-01T00:00:00.000000Z'),
          // end: dateFromString('2025-01-31T23:59:59.000000Z'),
          // stepDuration: 'month',
        },
        scope: {
          before: 0,
          after: -1,
        },
        chart: {
          // left: 0,
          // grid: {
          //   horizontal: {
          //     gap: 6 //*
          //   }
          // },
          // progress: {
          //   width: 20, //*
          //   height: 6, //*
          //   pattern: true,
          //   bar: false
          // },
          // text: {
            // color: '#ffffff',
          //   offset: 4, //*
          //   xPadding: 10, //*
          //   display: true //*
          // },
        },
        row: {
          height: 24,
        },
        // title: {
        //   label: 'Your project title as html (link or whatever...)',
        //   html: false
        // },
        calendar: {
          hour: {
            display: false
          },
          workingDays: [1, 2, 3, 4, 5],
        },
        taskList: {
          // display: false,

          columns: [
            {
              id: 1,
              label: '',
              value: 'description',
              width: 200,
              expander: true
            },
          ]
        }
      }
    }
  },
  methods: {
    buttonClick() {
      // this.$refs.gantt.options.scale_unit = "month";
      // console.log(this.$refs.gantt.options);
      // this.$refs.gantt.options.row.height = this.$refs.gantt.options.row.height + 1;
      this.$refs.gantt.options.times.timeZoom = this.$refs.gantt.options.times.timeZoom + 1;
    }
  }
}
</script>
<style>

</style>