import axios from "axios";
import { createFormData } from "@/service/general";

export const getResourcesApi = (query = null) => {
  return axios.get(`/api/admin/resources${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const getEmployeeDetailsApi = (id) => {
  return axios.get(`/api/admin/resources/${id}`)
}

export const addEmployeeApi = (payload) => {
  const formData = createFormData(payload)
  return axios.post(`/api/admin/resources`, formData, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
  });
}

export const updateEmployeeApi = (id, payload) => {
  const formData = createFormData(payload)
  return axios.post(`/api/admin/resources/${id}`, formData, {
    headers: {
        "Content-Type": "multipart/form-data",
    },
  });
}

export const deleteEmployeeApi = (id) => {
  return axios.delete(`/api/admin/resources/${id}`)
}


