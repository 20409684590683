<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <PhaseForm @loading="loading()" :id="projectId" :working="working" @formData="submitForm">
            <template #heading>
                <h2 class="u-font-500 mb0">Add New Phase</h2>
            </template>
        </PhaseForm>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import PhaseForm from '../project/PhaseForm.vue';
import { toast } from 'vue3-toastify';
import { addPhaseApi } from '../../api/projects'; 
export default {
    name: "PhaseAdd",
    components: {
        Header,
        PhaseForm,
    }, 
    data() {
        return {
            loadPage: true,
            working: false,
            projectId: null,
        }
    },
    methods: {
        loading(status) {
            this.loadPage = status
        },
        async submitForm(payload) {
            this.working = true
            try {
                await addPhaseApi(this.projectId,payload)
                this.working = false
                await this.$router.push({name: 'project-view-edit', params: { id: this.projectId }})
                toast.success('Your phase is successfully created', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        }
    },
    created() {
        this.projectId = this.$route.params.id;
    },
}
</script>
<style lang="scss" scoped>
    
</style>