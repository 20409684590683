<template>
    <div class="card h-100" v-if="loadPage">
        <div class="card_body h-100">
            <Loader/>
        </div>
    </div>
    <Form v-else :validation-schema="schema">
        <div class="card">
            <div class="card__header card--border o-flex o-flex--center o-flex--justify">
                <UploadImage :data="uploadImage" @uploadImg="uploadImg"/>
                <ToggleSwitch :id="'1'" :status="switchStatus" @status="handleStatusChange" class="toggle-switch"/>
            </div>
            <div class="card__body">
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>First name <span>*</span></p>
                            <div class="relative">
                                <Field type="text" name="firstName" class="input input--primary" :placeholder="'Enter'" v-model="firstName"/>
                                <ErrorMessage class="text-error" name="firstName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Last name <span>*</span></p>
                            <div class="relative">
                                <Field type="text" name="lastName" class="input input--primary" :placeholder="'Enter'" v-model="lastName"/>
                                <ErrorMessage class="text-error" name="lastName"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Start Date <span>*</span></p>
                            <div class="relative">
                                <Field name="startDate" v-slot="{ field }">
                                    <vue-date-picker
                                        v-bind="field"
                                        auto-apply
                                        :enable-time-picker="false"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="startDate"
                                        :ui="{ input: 'date-picker' }"
                                    />
                                </Field>
                                <ErrorMessage class="text-error" name="startDate"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>End Date</p>
                            <div class="relative">
                                <vue-date-picker
                                auto-apply
                                :enable-time-picker="false"
                                :model-type="'yyyy-MM-dd'"
                                :format="'dd.MM.yyyy'"
                                placeholder="Select Date"
                                v-model="endDate"
                                name="endDate"
                                :ui="{ input: 'date-picker' }"
                                :disabled="startDate === null"
                                :min-date="startDate"
                                />
                                <ErrorMessage class="text-error" name="endDate"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Email <span>*</span></p>
                            <div class="relative">
                                <Field type="email" name="email" class="input input--primary" :placeholder="'Enter'" v-model="email"/>
                                <ErrorMessage class="text-error" name="email"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Phone <span>*</span></p>
                            <div class="relative">
                                <Field type="phone" name="phone" class="input input--primary" :placeholder="'Enter'" v-model="phone"/>
                                <ErrorMessage class="text-error" name="phone"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Contract Type <span>*</span></p>
                            <div class="relative">
                                <VueMultiselect
                                    v-model="contractSelected"
                                    :options="contractType"
                                    label="name"
                                    track-by="id"
                                    @focus="validateContract"
                                    @select="clearErrorMessage"
                                    placeholder="Select one">
                                    <template #noResult>
                                        <span>No results</span>
                                    </template>
                                </VueMultiselect>
                                <span v-if="errorMessage" class="text-error">Select contract type</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="divider divider-1 mb"></div>

                <h2><strong>Employee Competencies</strong></h2>
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-6">
                        <div class="input-box">
                            <p>Positions</p>
                            <div class="relative">
                                <VueMultiselect
                                v-model="positions"
                                placeholder="Select one"
                                :options="allPositions"
                                :taggable="true"
                                :multiple="true"
                                label="name"
                                :close-on-select="true"
                                class="input--tags"
                                track-by="name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Seniority</p>
                            <div class="relative">
                                <VueMultiselect name="seniority" v-model="senioritySelected" placeholder="Select one" :options="allSeniorities" label="name" track-by="name">
                                    <template #noResult>
                                        <span>No results</span>
                                    </template>
                                </VueMultiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-12 col-lg-12">
                        <div class="input-box">
                            <p>Skills</p>
                            <VueMultiselect
                                v-model="skillsWithParents"
                                placeholder="Select one"
                                :options="allSkills"
                                :taggable="true"
                                :multiple="true"
                                label="name"
                                :close-on-select="true"
                                class="input--tags"
                                track-by="name"/>
                        </div>
                    </div>
                </div>
                <div class="divider divider-1 mb"></div>
                <h2><strong>Additional Info</strong></h2>
                <div class="row">
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Manager</p>
                            <div class="relative">
                                <VueMultiselect name="teamLead" v-model="teamLeadSelected" placeholder="Select one" :options="formattedProjectManagers" label="name" track-by="name">
                                    <template #noResult>
                                        <span>No results</span>
                                    </template>
                                </VueMultiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-3">
                        <div class="input-box">
                            <p>Location</p>
                            <div class="relative">
                                <VueMultiselect name="location" v-model="locationSelected" placeholder="Select one" :options="allLocations" label="name" track-by="name">
                                    <template #noResult>
                                        <span>No results</span>
                                    </template>
                                </VueMultiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card__footer card--border o-flex o-flex--justify o-flex--row-reverse">
                <div class="o-flex o-flex--center gap-10">
                    <router-link :to="{name: 'employee'}" class="button button--secondary">Cancel</router-link>
                    <button type="button" @click="submit" class="button button--primary" :disabled="working || disabledButton">Save Changes</button>
                </div>
                <button type="button" :disabled="payload.status === 'active'" v-if="payloadCheck" @click="$emit('delete')" class="button button--delete">Delete</button>
            </div>
        </div>
    </Form>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Loader from '../../components/Loader/Loader.vue';
import VueMultiselect from 'vue-multiselect';
import ToggleSwitch from '../../components/ToggleSwitch/ToggleSwitch.vue';
import UploadImage from '../../components/UploadImage/UploadImage.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { getPositions, getSkills, getSeniority, getTeamleads, getLocations, getContractTypes } from '../../service/general';

export default {
    name:"EmployForm",
    components: {
        Field,
        Form,
        ErrorMessage,
        VueDatePicker,
        VueMultiselect,
        ToggleSwitch,
        Loader,
        UploadImage,
    },
    emits: ['loading', 'formData', 'delete'],
    props: {
        working: {
            type: Boolean,
            default: false,
        },
        payload: {
            type: Object,
            default: {},
        },
    },

    data() {
        return {
            userId:null,
            errorMessage: false,
            photo: null,
            loadPage: true,
            firstName: "",
            lastName: "",
            switchStatus: "active",
            startDate: null,
            endDate: null,
            email: "",
            phone: "",
            contractSelected: null,
            contractType: [],
            positions: [],
            allPositions: [],
            skills: [],
            allSkills: [],
            senioritySelected: null,
            allSeniorities: [],
            teamLeadSelected: null,
            allTeamLeads: [],
            locationSelected: null,
            allLocations: [],
            schema: yup.object({
                firstName: yup
                .string()
                .required('First name is required')
                .min(2, 'Must be at least 2 characters'),
                lastName: yup
                .string()
                .required('Last name is required')
                .min(2, 'Must be at least 2 characters'),
                startDate: yup
                .date()
                .nullable()
                .required('Date is required'),
                email: yup
                .string()
                .required('Email is required')
                .email('Must be a valid email'),
                phone: yup
                .string()
                .required('Phone is required')
                .matches(/^[0-9]{10,15}$/, 'Phone number must be between 10 and 15 digits'),
                contractId: yup
                .object()
                .nullable()
                .shape({
                    id: yup.number().required('Contract type is required'),
                })
                .required('Contract type is required'),
            }),
        }
    },
    computed: {

        formattedProjectManagers() {
            return this.allTeamLeads.map(manager => ({
                ...manager,
                name: `${manager.first_name} ${manager.last_name}`,
            }));
        },
        skillsWithParents: {
            get() {
                let skillsWithParents = [...this.skills];
                this.skills.forEach(skill => {
                if (skill.parent_id) {
                    const parentSkill = this.allSkills.find(s => s.id === skill.parent_id);
                    if (parentSkill && !skillsWithParents.some(existingSkill => existingSkill.id === parentSkill.id)) {
                    skillsWithParents.push(parentSkill);
                    }
                }
                });

                return skillsWithParents;
            },
            set(newSkills) {
                this.skills = newSkills;
            }
        },
        disabledButton() {
            return this.firstName === '' || 
            this.lastName === '' || 
            this.firstName === '' || 
            this.startDate === null || 
            this.email === '' || 
            this.phone === '' || 
            this.contractSelected === null;
        },
        uploadImage() {
            const { file, first_name, last_name } = this.payload;

            return {
                file,
                first_name,
                last_name,
            };
        },
        
        payloadCheck() {
            return this.payload && Object.keys(this.payload).length > 0;
        }
    },
    methods: {
        uploadImg(file) {
            this.photo = file
        },
        validateContract() {
            this.errorMessage = true;
        },
        clearErrorMessage() {
            this.errorMessage = false;
        },
        handleStatusChange(newStatus) {
            this.switchStatus = newStatus;
        },

        updateData(payload) {
            if (payload) {
                if (payload.id !== undefined) this.userId = payload.id;
                if (payload.first_name !== undefined) this.firstName = payload.first_name;
                if (payload.last_name !== undefined) this.lastName = payload.last_name;
                if (payload.email !== undefined) this.email = payload.email;
                if (payload.phone !== undefined) this.phone = payload.phone;
                if (payload.start_date !== undefined) this.startDate = payload.start_date;
                if (payload.last_working_date !== undefined) this.endDate = payload.last_working_date;
                if (payload.status !== undefined) this.switchStatus = payload.status;
                if (payload.contract_type !== undefined) this.contractSelected = payload.contract_type;
                if (payload.seniority_level !== undefined) this.senioritySelected = payload.seniority_level;
                if (payload.team_lead !== null && payload.team_lead !== undefined) {
                    const { first_name, last_name } = payload.team_lead;
                    this.teamLeadSelected = {
                        ...payload.team_lead,
                        name: `${first_name} ${last_name}`
                    };
                }
                if (payload.location !== undefined) this.locationSelected = payload.location;
                if (Array.isArray(payload.skills) && payload.skills.length) {
                this.skills = payload.skills;
                }
                if (Array.isArray(payload.positions) && payload.positions.length) {
                this.positions = payload.positions;
                }
            }


            this.loadPage = false;
            this.$emit('loading', false);
        },

        async submit() {
            const skills = this.skills.map((item) => {
                return item.id
            })

            const positions = this.positions.map((item) => {
                return item.id
            })
            const payload = {
                file: this.photo,
                first_name: this.firstName,
                last_name: this.lastName,
                skill_ids: skills,
                status: this.switchStatus,
                position_ids: positions,
                email: this.email,
                phone: this.phone,
                start_date: this.startDate,
                last_working_date: this.endDate,
                seniority_level_id: this.senioritySelected?.id,
                location_id: this.locationSelected?.id,
                contract_type_id: this.contractSelected?.id,
                team_lead_id: this.teamLeadSelected?.id,
            }
            this.$emit('formData', payload);
        }
    },
    async created() {
        const [contractType, allPositions, allSkills, allSeniorities, allTeamLeads, allLocations]  = await Promise.all([getContractTypes(), getPositions(), getSkills(), getSeniority(), getTeamleads(), getLocations()])

        this.contractType = contractType;
        this.allPositions = allPositions;
        this.allSkills = allSkills;
        this.allSeniorities = allSeniorities;
        this.allTeamLeads = allTeamLeads;
        this.allLocations = allLocations;
        this.updateData(this.payload);
    },
}
</script>
<style lang="scss" scoped>
    .card__header {
        padding-bottom: 24px;
        padding-top: 9px;
    }

    .card__body {
        padding: 24px 0;
    }

    .card__footer {
        padding-top: 24px;
        padding-bottom: 9px;
        gap: 10px;
    }

    .input-box {
        margin-bottom: 24px;
    }
</style>