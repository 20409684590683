import axios from "axios";

export const fetchSkillTree = () => {
  return axios.get(`/api/admin/skills/tree`)
}

export const getAllSkills = () => {
  return axios.get(`/api/admin/skills/all`)
}

export const addSkillTree = (addSkill, id) => {
  const payload = {
    name: addSkill,
    parent_id: typeof id === 'number' ? id : ''
  };

  return axios.post('/api/admin/skills', payload);
};

export const updateSkillTree = (id, name, parentId, status) => {

  const payload = {
    name,
    parent_id: parentId,
    status: status
  };

  return axios.post(`/api/admin/skills/${id}`, payload);
};

export const deleteSkillTree = (id) => {
  return axios.delete(`/api/admin/skills/${id}`)
}


