<template>
    <main :class="{'h-100': loadPage}">
      <section class="project-filter">
        <div class="search-box">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                stroke="#637083"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                />
            </svg>
            <input
                v-model="searchTerm"
                @input="debouncedOnSearch"
                type="search"
                class="input input--primary input--search"
                placeholder="Search projects"
            />
        </div>
    
        <div class="o-flex o-flex--center">
          <div class="filter-wrapper mr-" v-click-outside="dropdownClose">
              <button @click="dropdown = true" class="button button--secondary" :class="{'active' : activeFilters}">
                  <svg width="20" height="20" class="mr-" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.50004 5.83333V3.5C2.50004 2.94772 2.94775 2.5 3.50004 2.5H16.5001C17.0524 2.5 17.5001 2.94766 17.5001 3.4999L17.5004 5.83333M2.50004 5.83333L7.98416 10.534C8.20581 10.724 8.33337 11.0013 8.33337 11.2933V16.2192C8.33337 16.8698 8.94476 17.3472 9.57591 17.1894L10.9092 16.856C11.3544 16.7447 11.6667 16.3448 11.6667 15.8859V11.2933C11.6667 11.0014 11.7943 10.724 12.0159 10.534L17.5004 5.83333M2.50004 5.83333H17.5004" stroke="#344051" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  Filter
              </button>
              <transition name="fade">
                  <div class="filter__dropdown" v-if="dropdown">
                      <div class="filter__dropdown--header o-flex o-flex--end o-flex--justify">
                          <div class="o-flex o-flex--center gap-10">
                              <img src="../../assets/img/filter-dropdown.svg" alt="">
                              <h2 class="u-font-500 mb0">Filter</h2>
                          </div>
                          <a v-if="activeFilters" href="javascript:;" @click="clearAll" class="clear">Reset All</a>
                      </div>
                      <div class="filter__dropdown--body">
                            <h3>Priority</h3>
                            <div class="relative">
                                <VueMultiselect
                                v-model="prioritiesSelected"
                                placeholder="Type..."
                                :options="allPriorities"
                                label="name"
                                :close-on-select="true"
                                class="input--tags mb-"
                                track-by="name"/>
                                <div class="u-text-right">
                                    <a href="javascript:;" class="clear" @click="prioritiesSelected = null">Clear</a>
                                </div>
                            </div>
                  
                            <div class="divider divider-1 mt- mb-"></div>
                            <h3>Status</h3>
                            <div class="relative">
                                <VueMultiselect
                                    v-model="statusSelected"
                                    placeholder="Select..."
                                    :options="allStatuses"
                                    label="name"
                                    :close-on-select="true"
                                    class="input--tags mb-"
                                    track-by="name"/>
                                <div class="u-text-right">
                                    <a href="javascript:;" class="clear" @click="statusSelected = null">Clear</a>
                                </div>
                            </div>
                 
                          <div class="divider divider-1 mt- mb-"></div>
                          <h3>Project Manager</h3>
                          <div class="relative">
                              <VueMultiselect
                              v-model="projectManagerSelected"
                              placeholder="Select..."
                              :options="formattedProjectManagers"
                              label="name"
                              :close-on-select="true"
                              class="input--tags mb-"
                              track-by="name"/>
                              <div class="u-text-right">
                                  <a href="javascript:;" class="clear" @click="projectManagerSelected = null">Clear</a>
                              </div>
                            </div>
                      </div>
                      <div class="filter__dropdown--footer">
                          <button type="button" class="button button--secondary" @click="dropdownClose">Cancel</button>
                          <button type="button" :disabled="filterWorking" @click="applyFilter" class="button button--primary">Apply</button>
                      </div>
                  </div>
              </transition>
          </div>
  
          <router-link
              :to="{name: 'add-project'}"
              class="button button--primary">
              <svg fill="none" width="20" viewBox="0 0 20 20">
                  <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 10h5m5 0h-5m0 0V5m0 5v5"/>
              </svg>
              Add new
          </router-link>
      </div>
      </section>
      <div class="card">
        <div v-if="!loadPage" class="card__header o-flex o-flex--center o-flex--justify">
          <div class="flag">
            <div class="flag__icon">
              <img src="@/assets/img/project.svg" alt="list" />
            </div>
            <div class="flag__content">
              <h2 class="u-font-500 mb0">Projects</h2>
              <p class="mb0">Manage your projects here</p>
            </div>
          </div>
      
        </div>
        <div class="card__body" :class="{'h-100': loadPage}">
            <NoData v-if="!loadPage && data.length === 0"/>
          <Loader v-if="loadPage"/>
          <div v-if="!loadPage && data.length > 0" class="table-responsive" >
            <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Manager</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th width="10%" class="u-text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="project in data" :key="project.id">
                    <td>
                      <div class="o-flex o-flex--center">
                        <span class="color-box-mark mr-" :style="`background-color: ${project.color}`"></span>
                        {{ project.name }}
                      </div>
                    </td>
                    <td>{{project.project_manager.first_name}} {{project.project_manager.last_name}}</td>
                    <td>
                      <PriorityBox :priority="project.priority"/>
                    </td>
                    <td>
                      <StatusBox :status="project.status"/>
                    </td>
                    <td class="u-text-center">
                      <router-link :to="{ name: 'project-view-edit', params: { id: project.id } }" @click.prevent="updateEditProjectId(project.id)"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.9693 4.70964L13.2026 3.47631C13.8535 2.82544 14.9088 2.82544 15.5597 3.47631L16.7382 4.65483C17.3891 5.3057 17.3891 6.36097 16.7382 7.01185L15.5049 8.24517M11.9693 4.70964L3.95627 12.7227C3.67954 12.9994 3.50902 13.3647 3.47458 13.7545L3.27284 16.0382C3.2272 16.5549 3.6596 16.9873 4.17627 16.9417L6.45996 16.7399C6.84979 16.7055 7.21508 16.535 7.49181 16.2582L15.5049 8.24517M11.9693 4.70964L15.5049 8.24517" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg></router-link>
                    </td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
    
        <div class="card__footer" v-if="!loadPage">
            <Pagination @page-click="getProjects" :info="pagination" />
        </div>
      </div>
    </main>
  </template>
  
<script>
import VueMultiselect from 'vue-multiselect'
import Pagination from "../../components/Pagination/Pagination.vue";
import { getPaginationPayload } from "../../service/general";
import { getProjectsApi } from '../../api/projects/index';
import { toast } from 'vue3-toastify';
import StatusBox from '../../components/StatusBox/StatusBox.vue';
import PriorityBox from '../../components/PriorityBox/PriorityBox.vue';
import Loader from '../../components/Loader/Loader.vue';
import { getProjectManagers } from '../../service/general';
import { priorities } from '@/utilities/priorities';
import { status } from '@/utilities/status';
import NoData from '@/components/NoData/NoData.vue';
  
    export default {
        name: 'ProjectPage',
    
        components: {
            VueMultiselect,
            Pagination,
            StatusBox,
            PriorityBox,
            Loader,
            NoData,
        },
    
        data() {
            return {
                loadPage: true,
                searchTerm: '',
                filterWorking:false,
                allProjectManagers: [],
                allStatuses: [],
                allPriorities: [],
                projectManagerSelected: null,
                prioritiesSelected: null,
                statusSelected: null,
                dropdown:false,
                pagination: null,
                roleId: null,
                data:[]
            };
        },
        computed: {
            activeFilters() {
                return (
                    this.projectManagerSelected !== null || 
                    this.prioritiesSelected !== null ||
                    this.statusSelected !== null
                );
            },
            formattedProjectManagers() {
                return this.allProjectManagers.map(manager => ({
                    ...manager,
                    name: `${manager.first_name} ${manager.last_name}`,
                }));
            }
        },
        methods: {
            updateEditProjectId(id) {
                localStorage.setItem('projectId', id); 
            },
            applyFilter() {
                this.dropdown = false;

                if (!this.projectManagerSelected && !this.prioritiesSelected && !this.statusSelected) {
                    localStorage.removeItem('filter');
                } else {
                    const filterData = {
                        priority: this.prioritiesSelected || null, 
                        status: this.statusSelected || null,
                        projectManager: this.projectManagerSelected || null,
                    };
                    localStorage.setItem('filter', JSON.stringify(filterData));
                }

                this.getProjects();
            },
            dropdownClose() {
                if(this.dropdown) {
                    this.dropdown = false
                }
            },
            clearAll() {  
                this.projectManagerSelected = null;
                this.prioritiesSelected = null;
                this.statusSelected = null;
                localStorage.removeItem('filter'); 
                this.getProjects()
            },
            async getProjects(page = 1) {
                this.filterWorking = true;
                const filterData = localStorage.getItem('filter');
                let filter = {};

                try {
                    filter = filterData ? JSON.parse(filterData) : {};
                } catch (e) {
                    toast.error("Failed to parse filter data:", {"position": "top-center", autoClose: 2000});
                    filter = {};
                }

                let payload;

                if (Object.keys(filter).length > 0) { 

                    this.projectManagerSelected = filter?.projectManager; 
                    this.prioritiesSelected = filter?.priority;
                    this.statusSelected = filter?.status;

                    payload = {
                        page: page,
                        ...(this.searchTerm && { term: this.searchTerm }),
                        priority: filter?.priority?.name,
                        status: filter?.status?.name,
                        project_manager_id: filter?.projectManager?.id 
                    };
                } else {
                    payload = {
                        page: page,
                        ...(this.searchTerm && { term: this.searchTerm }),
                        ...(this.prioritiesSelected !== null ? { priority: this.prioritiesSelected.name } : {}),
                        ...(this.statusSelected !== null ? { status: this.statusSelected.name } : {}),
                        ...(this.projectManagerSelected !== null ? { project_manager_id: this.projectManagerSelected.id } : {}),
                    };
                }
                
                try {
                    this.loadPage = true;
                    const queryParams = new URLSearchParams();
                    Object.keys(payload).forEach(key => {
                        if (Array.isArray(payload[key])) {
            
                            payload[key].forEach(value => {
                                queryParams.append(`${key}[]`, value);
                            });
                        } else if (payload[key] !== undefined && payload[key] !== null) {
                            queryParams.append(key, payload[key]); 
                        }
                    });
                    const { data } = await getProjectsApi(queryParams.toString());
                    this.data = data.data;
                    this.pagination = getPaginationPayload(data); 
                    this.loadPage = false; 
                    this.filterWorking = false;
                } catch (e) {
                    toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                }
            },
            onSearch() {
                this.getProjects();
            },

            debouncedOnSearch() {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = setTimeout(() => {
                this.onSearch();
                }, 500);
            }
        },
        mounted() {
            this.getProjects();
        },
        async created() {
            const [allProjectManagers]  = await Promise.all([getProjectManagers()])
            this.allProjectManagers = allProjectManagers;
            this.allStatuses = status
            this.allPriorities = priorities
        },
    };
    </script>
  
  <style lang="scss" scoped>
    main {
      display: flex;
      flex-direction: column;
      min-height: auto;
    }
    .filter-wrapper {
        position: relative;
    }

    .filter__dropdown {
        position: absolute;
        right: 0;
        top: calc(100% + 8px);
        background-color: #fff;
        border: 1px solid #E4E7EC;
        border-radius: 12px;
        width: 346px;
        padding: 24px 16px;
        z-index: 5;
    }

    .filter__dropdown--header {
        padding-bottom: 10px;
        border-bottom: 1px solid #E4E7EC;
    }

    .filter__dropdown--body {
        padding: 16px 0;
    }

    .filter__dropdown--footer {
        padding-top: 24px;
        border-top: 1px solid #E4E7EC;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .clear {
        color: #0166FF;
        font-size: 14px;
    }
    .project-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }
  
    .color-box-mark {
      width: 22px;
      height: 22px;
      border-radius: 6px;
      background-color: red;
    }
  </style>
  