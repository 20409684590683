import axios from "axios";

export const statuses = (query = null) => {
    return axios.get(`/api/admin/statuses${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addStatuses = (addStatus) => {
    return axios.post(`/api/admin/statuses`, {name: addStatus})
}

export const updateStatuses = (id, name) => {
    return axios.post(`/api/admin/statuses/`+ id, {name})
}

export const deleteStatuses = (id) => {
    return axios.delete(`/api/admin/statuses/` + id )
}

