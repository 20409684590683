import axios from "axios";

export const contractTypesApi = (query = null) => {
    return axios.get(`/api/admin/contract-types${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addContractTypeApi = (addContract) => {
    return axios.post(`/api/admin/contract-types`, {name: addContract})
}

export const updateContractTypeApi = (id, name, status) => {
    const payload = {
        id,
        name,
        status: status
    };
    return axios.post(`/api/admin/contract-types/`+ id, payload)
}

export const deleteContractTypeApi = (id) => {
    return axios.delete(`/api/admin/contract-types/` + id )
}

