<template>
    <main>
        <Header></Header>
        
        <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/add-project.svg" alt="add-project" />
                    </div> 
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Booking</h2>
                        <p class="mb0">Manage and book your project here</p>
                    </div>
                </div>
                <a href="javascript:;" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                    <svg fill="none" width="16" viewBox="0 0 16 16">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                    </svg>
                </a>
            </div>
            <transition name="fade">
                <div class="card__body" v-if="accordionActive">
                    <!-- <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Name <span>*</span></p>
                                <div class="relative">
                                    <Field type="text" name="name" class="input input--primary" :placeholder="'Enter'" v-model="name"/>
                                    <ErrorMessage class="text-error" name="name"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Start Date <span>*</span></p>
                                <div class="relative">
                                    <Field name="startDate" v-slot="{ field }">
                                        <vue-date-picker
                                            :enable-time-picker="false"
                                            auto-apply
                                            v-bind="field"
                                            :model-type="'yyyy-MM-dd'"
                                            :format="'dd.MM.yyyy'"
                                            placeholder="Select Date"
                                            v-model="startDate"
                                            :ui="{ input: 'date-picker' }"
                                        />
                                    </Field>
                                    <ErrorMessage class="text-error" name="startDate"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-2">
                            <div class="input-box">
                                <p>End Date</p>
                                <div class="relative">
                                    <Field name="endDate" v-slot="{ field }">
                                        <vue-date-picker 
                                        auto-apply
                                        v-bind="field"
                                        :enable-time-picker="false"
                                        :model-type="'yyyy-MM-dd'"
                                        :format="'dd.MM.yyyy'"
                                        placeholder="Select Date"
                                        v-model="endDate"
                                        name="endDate"
                                        :ui="{ input: 'date-picker' }"
                                        :disabled="!startDate || ongoingStatus"
                                        :min-date="minEndDate"
                                        />
                                    </Field>
                                    <ErrorMessage class="text-error" name="endDate"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-1 o-flex o-flex--center">
                            <div class="input-box mb0 mt-">
                                <div class="check-box">
                                    <input id="ongoing" type="checkbox" v-model="ongoingStatus" @change="ongoingChecked">
                                    <label for="ongoing">
                                        <div class="check-icon">
                                            <svg fill="none" width="16" viewBox="0 0 16 16">
                                                <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.333 8.667 6 11.333l6.667-6.666"/>
                                            </svg>
                                        </div>
                                        <p class="check-text">Ongoing</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Status</p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="statusSelected" placeholder="Select one" :options="statuses" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <h2 class="u-font-600">Project information</h2>

                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Project <span>*</span></p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Phase <span>*</span></p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>Start Date</p>
                                <div class="relative">
                                    <div class="date-box input--primary">
                                        <div class="icon-wrapper">
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 10.3566V19.3566C3 20.4612 3.89543 21.3566 5 21.3566H19C20.1046 21.3566 21 20.4612 21 19.3566V10.3566M3 10.3566H21M3 10.3566V6.3566C3 5.25203 3.89543 4.3566 5 4.3566H7M21 10.3566V6.3566C21 5.25203 20.1046 4.3566 19 4.3566H18.5M15 4.3566V2.3566M15 4.3566V6.3566M15 4.3566H10.5M7 6.3566V2.3566" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        {{ startDate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-3">
                            <div class="input-box">
                                <p>End Date</p>
                                <div class="relative">
                                    <div class="date-box input--primary">
                                        <div class="icon-wrapper">
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 10.3566V19.3566C3 20.4612 3.89543 21.3566 5 21.3566H19C20.1046 21.3566 21 20.4612 21 19.3566V10.3566M3 10.3566H21M3 10.3566V6.3566C3 5.25203 3.89543 4.3566 5 4.3566H7M21 10.3566V6.3566C21 5.25203 20.1046 4.3566 19 4.3566H18.5M15 4.3566V2.3566M15 4.3566V6.3566M15 4.3566H10.5M7 6.3566V2.3566" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        {{ endDate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
export default {
    name:"BookingProject",
    components: {
        Header,
    },
    data() {
        return {
            accordionActive: true,
            startDate: '08/08/2024',
            endDate: '08/08/2024',
        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
    .card__body {
        padding: 24px 0;
        margin-top: 10px;
        border-top: 1px solid #E4E7EC;
    }

    .accordion-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(21, 28, 36, 0.05);
        background-color: #141C25;
        svg {
            transform: rotate(180deg);
            transition: all .3s ease;
        }

        &:hover {
            background-color: darken(#141C25, 10%);
        }
        
        &.active {
            svg {
                transform: rotate(0deg);
            }
        }
    }
</style>