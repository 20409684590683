<template>
    <div>
        timeline
    </div>
</template>
<script>
export default {
    name: 'BookingTimeline'
}
</script>
<style lang="scss" scoped>
    
</style>