<template>
    <div class="loader"></div>
</template>
<script>
export default {
    name: "ImageLoader"
}
</script>
<style lang="scss" scoped>
    .loader {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
        border-top: 3px solid #0166FF;
        border-right: 3px solid transparent;
        box-sizing: border-box;
        animation: rotation .5s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 
</style>