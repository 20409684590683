import axios from "axios";

export const clientsApi = (query = null) => {
    return axios.get(`/api/admin/clients${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addClientsApi = (addClient) => {
    return axios.post(`/api/admin/clients`, {name: addClient})
}

export const updateClientsApi = (id, name, status) => {
    const payload = {
        id,
        name,
        status: status
    };
    return axios.post(`/api/admin/clients/`+ id, payload)
}

export const deleteClientsApi = (id) => {
    return axios.delete(`/api/admin/clients/` + id )
}

