<template>
    <span ref="svg" v-html="icon"></span>
</template>

<script>

export default {
    name: "SvgIcons",
    inheritAttrs: true,
    props: {
        icon: {
            type: String,
            required: true,
        },
        iconWidth: {
            type: String,
            default: '1rem'
        },
        iconHeight: {
            type: String,
            default: '1rem'
        },
        rotation: {
            type: String,
            default: '0'
        },
        fill: {
            type: String,
            default: '0'
        },
        stroke: {
            type: String,
            default: '0'
        },
    },

    mounted() {
        const svgElement = this.$el.firstChild;

        svgElement.setAttribute('height', this.iconHeight)
        svgElement.setAttribute('width', this.iconWidth)
        if(this.rotation !== '0') svgElement.style.setProperty('transform', `rotate(${this.rotation})`);
        if(this.fill !== '0') svgElement.firstChild.style.setProperty('fill', this.fill, 'important');
        if(this.stroke !== '0') {
            svgElement.style.setProperty('--stroke-color', this.stroke, 'important');
            svgElement.setAttribute("data-has-stroke-color", "true");
        }
    }
}
</script>

<style lang="scss" scoped>
span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

svg {
    display: inherit;
    margin: auto;
}

svg[data-has-stroke-color] {
    path {
        stroke: var(--stroke-color) !important;
    }
}

</style>
