<template>
  <main :class="{'h-100': loadPage}">
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModal"
          class="modal"
          :closeButton="false"
          @close="openModal = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <SvgIcon :icon="svgIcons.modalPen"
                               icon-width="48px"
                               icon-height="48px"/>
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Add new Teams</h2>
                <p class="mb0">Description text goes here</p>
              </div>
            </div>
          </template>
          <template #body>
            <Form :validation-schema="schema">
              <div class="input-box">
                  <p>Teams name</p>
                  <div class="relative">
                      <Field type="text" name="addTeams" class="input input--primary" ref="teamsInputAdd" :placeholder="'Enter'" maxlength="20" v-model="teamsValue"/>
                      <ErrorMessage class="text-error" name="addTeams"  />
                  </div>
              </div>
            </Form>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModal = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingAdd || teamsValue.length <= 1" @click="addTeams" class="button button--primary">Save</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModalEdit"
          class="modal"
          :closeButton="false"
          @close="openModalEdit = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <SvgIcon :icon="svgIcons.modalPen"
                               icon-width="48px"
                               icon-height="48px"/>
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Edit Teams</h2>
                <p class="mb0">Description text goes here</p>
              </div>
            </div>
          </template>
          <template #body>
            <Form :validation-schema="schema">
              <div class="input-box">
                  <p>Teams name</p>
                  <div class="relative">
                      <Field type="text" name="addTeams" class="input input--primary" ref="teamsInputEdit" :placeholder="'Enter'" maxlength="20" v-model="teamsValue"/>
                      <ErrorMessage class="text-error" name="addTeams"  />
                  </div>
              </div>
              <ToggleSwitch :id="'1'" :status="switchStatus" @status="handleStatusChange" class="toggle-switch"/>
            </Form>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModalEdit = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingEdit || teamsValue.length <= 1" @click="updateTeams" class="button button--primary">Save</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <teleport to="#modals">
      <transition name="fade">
        <Modal
          v-if="openModalDelete"
          class="modal"
          :closeButton="false"
          @close="openModalDelete = false"
        >
          <template #header>
            <div class="flag">
              <div class="flag__icon">
                <SvgIcon :icon="svgIcons.modalPen"
                               icon-width="48px"
                               icon-height="48px"/>
              </div>
              <div class="flag__content">
                <h2 class="u-font-500 mb0">Delete Teams</h2>
              </div>
            </div>
          </template>
          <template #body>
            <p class="mb0">Are you sure you want to delete <strong>- {{teamsValue}} -</strong></p>
          </template>
          <template #footer>
            <div class="o-flex modal-button-wrapper">
              <a
                href="#"
                class="button button--secondary"
                @click="openModalDelete = false"
                >Cancel</a
              >
              <button type="button" :disabled="workingDelete" @click="deleteTeams" class="button button--primary">Delete</button>
            </div>
          </template>
        </Modal>
      </transition>
    </teleport>
    <div class="card">
      <div v-if="!loadPage" class="card__header o-flex o-flex--center o-flex--justify">
        <div class="flag">
          <div class="flag__icon">
            <SvgIcon  :icon="svgIcons.teamsIcon"
                               icon-width="38px"
                               icon-height="38px"/>
          </div>
          <div class="flag__content">
            <h2 class="u-font-500 mb0">Teams</h2>
            <p class="mb0">Manage your teams here</p>
          </div>
        </div>
        <a
          href="javascript:;"
          @click="addModalHandler"
          class="button button--primary"
          >Add new</a
        >
      </div>
      <div class="card__body" :class="{'h-100': loadPage}">
        <Loader v-if="loadPage"/>
        <div v-else class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th>Date Added</th>
                <th>Status</th>
                <th width="10%" class="u-text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data" :key="item.id">
                <td>{{item.name}}</td>
                <td>{{formatDate(item.created_at)}}</td>
                <td>
                  <StatusBox :status="item.status"/>
                </td>
                <td>
                  <ul
                    class="o-flex o-flex--center o-flex--justify-center action-list"
                  >
                    <li>
                      <a href="javascript:;" class="icon-trigger" @click="editModalHandler(item.id, item.name, item.status)">
                        <SvgIcon  :icon="svgIcons.edit"
                               icon-width="20px"
                               icon-height="20px"/>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" class="icon-trigger" @click="deleteModalHandler(item.id, item.name)">
                        <SvgIcon  :icon="svgIcons.delete"
                               icon-width="20px"
                               icon-height="20px"/>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card__footer">
          <Pagination @page-click="getTeams" :info="pagination" />
      </div>
    </div>
  </main>
</template>
<script>
import Modal from '../components/Modal/Modal.vue';
import StatusBox from '../components/StatusBox/StatusBox.vue';
import ToggleSwitch from '../components/ToggleSwitch/ToggleSwitch.vue';
import {teamsApi, addTeamsApi, updateTeamsApi, deleteTeamsApi} from '../api/teams/index';
import moment from "moment";
import Pagination from "../components/Pagination/Pagination.vue";
import { getPaginationPayload } from "../service/general";
import { toast } from 'vue3-toastify';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import Loader from '../components/Loader/Loader.vue';
import SvgIcon from '@/components/SvgIcons/SvgIcons.vue'
import svgIcons from '@/assets/icons/icons';

export default {
  name: 'Teams',
  components: {
    Modal,
    Pagination,
    Field,
    Form,
    ErrorMessage,
    StatusBox,
    ToggleSwitch,
    Loader,
    SvgIcon,
  },

  data() {
    return {
      svgIcons,
      loadPage: true,
      workingAdd: false,
      workingEdit: false,
      workingDelete: false,
      openModal: false,
      openModalEdit: false,
      openModalDelete: false,
      data:[],
      pagination: null,
      teamsValue: "",
      teamsId: null,
      switchStatus: null,
      schema: yup.object({
        addTeams: yup
          .string()
          .required('Teams value is required')
          .min(2, 'Must be at least 2 characters')
      }),
    };
  },

  methods: {
    handleStatusChange(newStatus) {
      this.switchStatus = newStatus;
    },
    
    async getTeams(page = 1) {
      
      try {
          const { data } = await teamsApi({page:page});
          this.data = data.data
          this.loadPage = false;
          this.pagination = getPaginationPayload(data)
      } catch (e) {
          toast.error(e);
      }
    },
    
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
        
    addModalHandler() {
      this.teamsValue= "";
      this.openModal = true;
      this.$nextTick(() => {
        if (this.$refs.teamsInputAdd) {
          this.$refs.teamsInputAdd.$el.focus();
        }
      });
    },

    async addTeams() {
      this.workingAdd = true;
      try {
        await addTeamsApi(this.teamsValue);
        toast.success(this.teamsValue + " is added.", {"position": "top-center", autoClose: 2000});
        this.teamsValue = '';
        this.openModal = false;
        this.workingAdd = false;
        this.getTeams();
      } catch (e) {
        toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingAdd = false;
        }, "3000");
      }
    },

    editModalHandler(id, name, status) {
      this.teamsValue = name;
      this.teamsId = id;
      this.switchStatus = status;
      this.openModalEdit = true;
      this.$nextTick(() => {
        if (this.$refs.teamsInputEdit) {
          this.$refs.teamsInputEdit.$el.focus();
        }
      });
    },

    async updateTeams() {
      this.workingEdit = true;
      try {
        await updateTeamsApi(this.teamsId, this.teamsValue, this.switchStatus);
        toast.success(this.teamsValue + " is edited.", {"position": "top-center", autoClose: 2000});
        this.teamsValue = '';
        this.openModalEdit = false;
        this.workingEdit = false;
        this.getTeams();
      } catch (e) {
        toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingEdit = false;
        }, "3000");
      }
    },

    deleteModalHandler(id, name) {
      this.teamsValue = name;
      this.teamsId = id;
      this.openModalDelete = true;

    },

    async deleteTeams() {
      this.workingDelete = true;
      try {
        await deleteTeamsApi(this.teamsId);
        toast.success(this.teamsValue + " is deleted successfuly.", {"position": "top-center", autoClose: 2000});
        this.teamsValue = '';
        this.openModalDelete = false;
        this.workingDelete = false;
        this.getTeams();
      } catch (e) {
        toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
        setTimeout(() => {
          this.workingDelete = false;
        }, "3000");
      }
    },
  },
  mounted() {
    this.getTeams();
  }
};
</script>
<style lang="scss" scoped>
.modal-button-wrapper {
  gap: 16px;

  .button {
    height: 44px;
  }
}

.flag__content {
  color: var(--light-dark);
}
</style>