import axios from "axios";

export const seniorityApi = (query = null) => {
    return axios.get(`/api/admin/seniority-levels${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const addSeniorityApi = (addSeniority) => {
    return axios.post(`/api/admin/seniority-levels`, {name: addSeniority})
}

export const updateSeniorityApi = (id, name, status) => {
    const payload = {
        id,
        name,
        status: status
    };
    return axios.post(`/api/admin/seniority-levels/`+ id, payload)
}

export const deleteSeniorityApi = (id) => {
    return axios.delete(`/api/admin/seniority-levels/` + id )
}

