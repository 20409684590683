<template>
  <div>
    <Timeline :tasks="tasks"></Timeline>
  </div>
</template>

<script>
  import Timeline from "@/components/Timeline/Timeline.vue";
  import { dateFromString } from "@/service/DateTime"
  import { getTestDataApi } from "@/api/bookings";
  import { createTaskListFromApiData } from "@/service/Timeline"
  import { toast } from "vue3-toastify";

  export default {
    name: 'Test4',
    components: {
      Timeline,
    },
    data() {
      return {
        tasks: [
          // {
          //   id: 1,
          //   description: 'Nikola Skaramuca',
          //   start: dateFromString('2024-10-01T00:00:00.000000Z'),
          //   end: dateFromString('2024-10-31T23:59:59.000000Z'),
          //   progress: 0,
          //   type: 'task',
          //   style: {
          //     base: {
          //       display: 'none',
          //     }

          //   }
          // },

          // {
          //   id: 2,
          //   label: 'Some label',
          //   description: 'Workforce Planning',
          //   start: dateFromString('2024-10-02T00:00:00.000000Z'),
          //   end: dateFromString('2024-10-31T23:59:59.000000Z'),
          //   progress: 0,
          //   type: 'task',
          //   parentId: 1,
          // },

          // {
          //   id: 3,
          //   label: 'Some label',
          //   description: 'Lafarge',
          //   start: dateFromString('2024-10-02T00:00:00.000000Z'),
          //   end: dateFromString('2025-01-31T23:59:59.000000Z'),
          //   progress: 0,
          //   type: 'task',
          //   parentId: 1,
          //   style: {
          //     "task-list-header-label": {
          //       textAlign: "center",
          //       width: "100%"
          //     },
          //     "task-list-item-value-container": {
          //       textAlign: "center"
          //     },
          //   }
          // },

          // {
          //   id: 4,
          //   description: 'Joca Fitilj',
          //   start: dateFromString('2024-10-01T00:00:00.000000Z'),
          //   duration: 1,
          //   progress: 0,
          //   type: 'task',
          // },

          // {
          //   id: 5,
          //   description: 'Workforce Planning Joca',
          //   start: dateFromString('2024-10-01T00:00:00.000000Z'),
          //   end: dateFromString('2024-10-31T23:59:59.000000Z'),
          //   progress: 0,
          //   type: 'task',
          //   parentId: 4,
          // },

          // {
          //   id: 6,
          //   description: 'Lafarge Joca',
          //   start: dateFromString('2024-10-01T00:00:00.000000Z'),
          //   end: dateFromString('2025-01-31T23:59:59.000000Z'),
          //   progress: 0,
          //   type: 'task',
          //   parentId: 4,
          // }

        ],
      }
    },
    methods: {

    },

    async created() {
      try {
        const { data } = await getTestDataApi();
        this.tasks = createTaskListFromApiData(data.data, data.start_date, data.end_date);
      } catch (e) {
        toast.error(e.response?.data.message);
      }
    },
  }
</script>

<style>

</style>