import { dateFromString } from "@/service/DateTime";

export const createTaskListFromApiData = (data, startDate, endDate) => {
  if (typeof startDate === 'string') startDate = dateFromString(startDate);
  if (typeof endDate === 'string') endDate = dateFromString(endDate);

  const tasks = [];

  data.forEach(rawData => {
    let parentTask = findParentTask(tasks, generateParentTaskId(rawData.resource.id));

    if (!parentTask) {
      parentTask = createParentTaskFromApiData(rawData.resource, startDate, endDate);
      tasks.push(parentTask);
    }

    tasks.push(createTaskFromApiData(rawData, parentTask.id, startDate, endDate));
  });

  if (tasks.length === 0) {
    tasks.push(createPlaceHolderTaskFromApiData(startDate, endDate));
  }

  return tasks;
};

const generateParentTaskId = (rawId) => 'resource_' + rawId;
const generateTaskId = (rawId) => 'task_' + rawId;
const findParentTask = (tasks, parentTaskId) => {
  return tasks.find((task) => task.id === parentTaskId);
}
export const createParentTaskFromApiData = (resourceRawData, startDate, endDate) => {
  return {
    id: generateParentTaskId(resourceRawData.id),
    description: resourceRawData.first_name + ' ' + resourceRawData.last_name,
    start: startDate,
    end: endDate,
    progress: 0,
    type: 'task',
    style: {
      base: {
        display: 'none',
      }
    }
  };
};

export const createTaskFromApiData = (taskRawData, parentId, startDate, endDate) => {
  const taskStartDate = dateFromString(taskRawData.start_date);

  return  {
    id: generateTaskId(taskRawData.id),
    label: taskRawData.skill.name + ' - ' + taskRawData.hours_per_day + 'h',
    description: taskRawData.project.name + ' - ' + taskRawData.phase.name,
    start: (taskStartDate > startDate) ? taskStartDate : startDate,
    end: taskRawData.end_date ? dateFromString(taskRawData.end_date) : endDate,
    progress: 0,
    type: 'task',
    parentId,
  };
};

export const createPlaceHolderTaskFromApiData = (startDate, endDate) => {
  return {
    id: 'placeholder',
    description: '',
    start: startDate,
    end: endDate,
    progress: 0,
    type: 'task',
    style: {
      base: {
        display: 'none',
      }
    }
  };
};