<template>
    <input 
    type="text"
    v-model="inputValue"
    @input="validateInput"
    class="input input--primary"
    />
</template>
  
  <script>
  export default {
    name: "NumberInput",
    emits:['number'],
    props: {
        hour: {
            type: String,
            default: ''
        },
        max: {
            type: Number,
            default: 0
        }
    },
    data() {
      return {
        inputValue: ""
      };
    },
    methods: {
      validateInput(event) {
        const value = event.target.value;
        this.inputValue = value.replace(/\D/g, "").slice(0, this.max);

        this.$emit('number',this.inputValue);
      }
    },
    created() {
        this.inputValue = this.hour;
    }
  };
  </script>
  
  <style scoped>

  </style>