<template>
    <div class="no-data mh-100">
        <svg width="50" viewBox="0 0 512 512">
            <path d="M32 96c0-17.7 14.3-32 32-32h124.1c8.5 0 16.6 3.4 22.6 9.4l35.9 35.9c12 12 28.3 18.7 45.3 18.7H448c17.7 0 32 14.3 32 32v256c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96zm32-64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H291.9c-8.5 0-16.6-3.4-22.6-9.4l-35.9-35.9c-12-12-28.3-18.7-45.3-18.7H64zm116.7 180.7c-6.2 6.2-6.2 16.4 0 22.6l52.7 52.7-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l52.7-52.7 52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 288l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 265.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z"/>
        </svg>
        <br>
        No data...
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .no-data {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 300px;
        font-size: 16px;
        font-weight: 500;

        svg {
            margin-bottom: 20px;
        }
    }
</style>