<template>
    <img ref="child" v-if="this.objectUrl" :src="this.objectUrl" />
</template>

<script>
import axios from "axios";

export default {
    name: "AuthorizedImg",

    props: {
        src: String,
    },

    data() {
        return {
            /**
             * The URL we use as the actual src.
             *
             * @type {string | null}
             */
            objectUrl: null,
        };
    },

    methods: {
        async fetchSrc() {
            try {
                const response = await axios.get(this.src, {
                    responseType: "blob",
                    redirectOnNotFound: false,
                });

                if (this.objectUrl) {
                    URL.revokeObjectURL(this.objectUrl);
                }
                this.objectUrl = URL.createObjectURL(response.data);
            } catch (err) {
                if (this.objectUrl) {
                    URL.revokeObjectURL(this.objectUrl);
                }
                this.objectUrl = null;
                console.error(`Failed to fetch ${this.src}:`, err);
            }
        },
    },

    watch: {
        src: {
            immediate: true,
            handler(src) {
                if (!src) {
                    if (this.objectUrl) {
                        URL.revokeObjectURL(this.objectUrl);
                    }
                    this.objectUrl = null;
                    return;
                }

                this.fetchSrc();
            },
        },
    },

    destroyed() {
        if (this.objectUrl) {
            URL.revokeObjectURL(this.objectUrl);
        }
        this.objectUrl = null;
    },
};
</script>
