<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <ProjectForm @loading="loading()" :working="working" @formData="submitForm"/>
    </main>
</template>
<script> 
import Header from '@/components/Header/Header.vue';
import ProjectForm from '../project/ProjectForm.vue';
import { addProjectsApi } from '../../api/projects/index';
import { toast } from 'vue3-toastify';

export default {
    name: "AddProject",
    components: {
        Header,
        ProjectForm,
    }, 
    data() {
        return {
            loadPage: true,
            working: false,
        }
    },
    methods: {
        loading(status) {
            this.loadPage = status
        },
        async submitForm(payload) {
            this.working = true
            try {
                await addProjectsApi(payload)
                this.working = false
                await this.$router.push({name: 'project'})
                toast.success('Your project is successfully created', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>