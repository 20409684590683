export const status = [
    {
        id: 1,
        name: "not-started",
    },
    {
        id: 2,
        name: "in-progress",
    },
    {
        id: 3,
        name: "on-hold",
    },
    {
        id: 4,
        name: "maintenance",
    },
    {
        id: 5,
        name: "completed",
    },
    {
        id: 6,
        name: "done",
    },
]