<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <ProjectForm v-if="data" :payload="data" :accordion="true" @loading="loading()" @delete="deleteModal" :working="working" @formData="submitForm"/>
        <Phases v-if="!loadPage" :data="phases" :id="data.id"/>
        
        <teleport to="#modals">
            <transition name="fade">
                <Modal
                v-if="openModalDelete"
                class="modal"
                :closeButton="false"
                @close="openModalDelete = false"
                >
                <template #header>
                    <div class="flag">
                    <div class="flag__icon">
                        <img
                        src="@/assets/img/add-contract-type.svg"
                        alt="add-contract-type"
                        />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Delete Project</h2>
                    </div>
                    </div>
                </template>
                <template #body>
                    <p class="mb0">Are you sure you want to delete <strong>- {{data.name}} -</strong></p>
                </template>
                <template #footer>
                    <div class="o-flex modal-button-wrapper">
                        <a
                            href="#"
                            class="button button--secondary"
                            @click="openModalDelete = false"
                            >Cancel</a
                        >
                        <button type="button" :disabled="workingDelete" @click="deleteProject" class="button button--primary">Delete</button>
                    </div>
                </template>
                </Modal>
            </transition>
        </teleport>
    </main>
</template>
<script>
import Header from '@/components/Header/Header.vue';
import ProjectForm from './ProjectForm.vue';
import { toast } from 'vue3-toastify';
import { getProjectDetailsApi, updateProjectApi, deleteProjectApi } from '../../api/projects'; 
import Modal from '../../components/Modal/Modal.vue';
import Phases from './Phases.vue';

export default {
    name:"ProjectViewEdit",
    components: {
        Header,
        ProjectForm,
        Modal,
        Phases
    }, 
    data() {
        return {
            openModalDelete:false,
            userId: null, 
            loadPage: true,
            data: null,
            working: false,
            workingDelete: false,
            phases: [],
        };
    },
    methods: {
        
        deleteModal() {
            this.openModalDelete = true
        },  
        loading(status) {
            this.loadPage = status 
        },
        async getProjectDetails() {
            try {
                const { data } = await getProjectDetailsApi(this.userId);
                this.data = data
                this.phases = data.phases
            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
            }
        },
        async submitForm(payload) {
            this.working = true
            try {
                await updateProjectApi(this.userId,payload)
                this.working = false
                await this.$router.push({name: 'project'})
                toast.success('Your project is successfully edited', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        },
        async deleteProject() {
            
            this.workingDelete = true
            try {
                await deleteProjectApi(this.userId)
                this.workingDelete = false
                await this.$router.push({name: 'project'})
                toast.success('Your project is successfully deleted', {"position": "top-center", autoClose: 2000});

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.workingDelete = false;
                }, "3000");
            }
        },
    },
    created() {
        this.userId = this.$route.params.id;
        this.getProjectDetails();
    },
}
</script>
<style lang="scss" scoped>

    main {
        min-height: 100%;
    }
    
    .modal-button-wrapper {
        gap: 16px;

        .button {
            height: 44px;
        }
    }
    .card {
        margin-top: 16px;
    }

    
</style>